import {
  CoverageStatus,
  CoverageType,
  CoverageTypeCode,
  IFullName,
  RelationshipType,
} from 'scripts/api/api.interfaces';
import {
  ICoverageInfo,
  IPlanCoverage,
  IPrimaryCarePerMember,
  IProfileUser,
  IUserAddress,
  IProfileUserInfo,
  IReferralsPerMember,
  LineOfBusiness,
  MarketNumber,
  MarketType,
  MembershipCategory,
  MrSnpType,
  ProgramType,
  RxCarveOutProvider,
  RxCoverageType,
  SourceSystem,
  State,
} from 'scripts/api/profile/profile.interfaces';
import CONFIG from '../constants/config';
import { getResource } from '../resource/resource';
import { optumPharmacy } from '../resource/resource.constants';
import { IPopulation } from '../population/population.interfaces';
import { ICoverageSection } from 'scripts/features/modals/id-cards/id-cards.interfaces';
import { IConfig } from '../constants/environment.interfaces';
import { getConfig } from 'scripts/util/config/config';
import { format, isSameDayOrAfter, isSameYearOrAfter, subYears } from '../date/date';
import { splitConfig } from 'scripts/util/config/config-utils';
import { getGroupNumber } from '../plans/plans';
import { IPlanBenefits } from 'scripts/api/plans/plans.interfaces';

export function getCoverage(coverageType: CoverageType, coverages: IPlanCoverage[]): IPlanCoverage | undefined {
  for (const coverage of coverages) {
    if (coverage.coverageType === coverageType) {
      return coverage;
    }
  }
}

export function getCoverageInfo(
  coverages: IPlanCoverage[],
  statuses: CoverageStatus[] = [CoverageStatus.Active],
): ICoverageInfo {
  const coverageInfo: ICoverageInfo = {
    coverageTypes: {},
    coverageTypeCodes: {},
    numCoverages: 0,
  };
  if (coverages) {
    for (const c of coverages) {
      const hasCoverage = statuses.indexOf(c.planPeriod.status) > -1;
      if (hasCoverage) {
        coverageInfo.coverageTypes[c.coverageType] = true;
        if (c.coverageTypeCode) {
          coverageInfo.coverageTypeCodes[c.coverageTypeCode] = true;
        }
        for (const subCoverageType of c.additionalCoverageTypes) {
          coverageInfo.coverageTypes[subCoverageType] = true;
        }
      }
    }
  }
  coverageInfo.numCoverages = Object.keys(coverageInfo.coverageTypes).length;
  return coverageInfo;
}

export function getAdditionalCoverages(coverages: IPlanCoverage[]): Set<CoverageType> {
  const additionalCoverages: Set<CoverageType> = new Set<CoverageType>();
  if (coverages) {
    for (const c of coverages) {
      const activeCoverage = c.planPeriod.status === CoverageStatus.Active;
      if (activeCoverage) {
        for (const subCoverageType of c.additionalCoverageTypes) {
          additionalCoverages.add(subCoverageType);
        }
      }
    }
  }
  return additionalCoverages;
}

export function getCoverageTypeCodesFromProfileUser(profile: IProfileUser): CoverageTypeCode[] {
  return profile.planCoverages.map(coverage => coverage.coverageTypeCode).filter(_ => _);
}

export function getProgramTypesFromProfileUser(profile: IProfileUser): ProgramType[] {
  return profile.planCoverages.map(coverage => coverage.planFeatures.programType).filter(_ => _);
}

export function getGroupNumbersFromProfileUser(profile: IProfileUser): string[] {
  return profile.planCoverages.map(coverage => getGroupNumber(coverage as ICoverageSection, profile)).filter(_ => _);
}

export function getFullName(user: IFullName): string {
  let fullName = '';
  if (user?.firstName) {
    fullName = user.firstName + ' ';
  }
  if (user?.firstName && user?.middleName) {
    fullName += user.middleName + ' ';
  }
  if (user?.lastName) {
    fullName += user.lastName;
  }
  if (user?.suffix) {
    fullName += ' ' + user.suffix;
  }
  return fullName;
}
export function hasActiveReferrals(
  referralsPerMember: IReferralsPerMember,
  selectedUser: IProfileUser,
  dependents: IProfileUser[],
): boolean {
  return (
    getNumberOfDependentReferrals(referralsPerMember, dependents) +
      getNumberOfSelectedUserReferrals(referralsPerMember, selectedUser) >
    0
  );
}

export const getUserAddress = (userInfo: IProfileUserInfo): IUserAddress => {
  const { state, address1, city, zipCode } = userInfo;
  const formattedState = state ? state : '';
  const formattedAddress1 = address1 ? address1.toLowerCase().replace(/\b\w/g, c => c.toUpperCase()) : '';
  const formattedCity = city ? city.toLowerCase().replace(/\b\w/g, c => c.toUpperCase()) : '';
  return {
    formattedAddress1,
    formattedCity,
    formattedState,
    zipCode,
    fullFormattedAddress: `${formattedAddress1}, ${formattedCity}, ${formattedState} ${zipCode}`,
  };
};

export function getNumberOfSelectedUserReferrals(
  referralsPerMember: IReferralsPerMember,
  selectedUser: IProfileUser,
): number {
  const selectedUserReferrals = referralsPerMember && referralsPerMember[selectedUser.dependentSeqNum];
  return selectedUserReferrals ? selectedUserReferrals.totalNumberOfActiveReferrals : 0;
}

export function getNumberOfDependentReferrals(
  referralsPerMember: IReferralsPerMember,
  dependents: IProfileUser[],
): number {
  if (!referralsPerMember) {
    return 0;
  }

  let numDependentReferrals = 0;
  dependents.forEach(dependent => {
    const referrals = referralsPerMember[dependent.dependentSeqNum];
    if (dependent.userInfo?.age < 12 && !!referrals) {
      numDependentReferrals += referrals.totalNumberOfActiveReferrals;
    }
  });
  return numDependentReferrals;
}

export function getRelationshipTypeText(currentUser: IProfileUser): string | IProfileUser['relationshipType'] {
  if (
    currentUser.membershipCategory &&
    currentUser.membershipCategory === MembershipCategory.EMPIRE &&
    currentUser.relationshipType === RelationshipType.Subscriber
  ) {
    return 'ENROLLEE';
  }
  return currentUser.relationshipType;
}

export function getRelationshipType(
  user: IProfileUser,
  coverage: ICoverageSection,
): string | IProfileUser['relationshipType'] {
  if (coverage.planFeatures.programType === ProgramType.Ship) {
    return 'INSURED_MEMBER';
  } else {
    return getRelationshipTypeText(user);
  }
}

export function getRxProviderMap(population: IPopulation): {} {
  return {
    [RxCarveOutProvider.Optum]: {
      logo: '/images/branding/optum-rx.svg',
      displayName: 'OptumRx',
      url: getResource(optumPharmacy, population),
    },
    [RxCarveOutProvider.Caremark]: {
      logo: '/images/branding/cvs-caremark.svg',
      displayName: 'CVS Caremark',
      url: CONFIG.ARCADE_WEB_CAREMARK_URL,
    },
    [RxCarveOutProvider.ExpressScripts]: {
      logo: '/images/branding/express-scripts.svg',
      displayName: 'Express Scripts',
      url: CONFIG.ARCADE_WEB_EXPRESS_SCRIPTS_URL,
    },
    [RxCarveOutProvider.Walgreens]: {
      logo: '/images/branding/walgreens.svg',
      displayName: 'Walgreens',
      url: CONFIG.ARCADE_WEB_WALGREENS_URL,
    },
  };
}

export function getRxProvider(coverage: IPlanCoverage): string {
  if (coverage.coverageType !== CoverageType.Rx || typeof coverage.rxCoverageInfo === 'undefined') {
    return;
  }

  return coverage.rxCoverageInfo.coverageType === RxCoverageType.CRX
    ? coverage.rxCoverageInfo.carveOutProvider
    : RxCarveOutProvider.Optum;
}

export function hasAdditionalActiveCoverageForCoverageType(
  coverageType: CoverageType,
  coverages: IPlanCoverage[],
): boolean {
  const additionalCoverageTypes = getAdditionalCoverages(coverages);
  return additionalCoverageTypes.has(coverageType);
}

export function hasActiveCoverageForCoverageType(coverageType: CoverageType, coverages: IPlanCoverage[]): boolean {
  let mnrHasDental = false;
  // MnR will have dental coverage indicated under medical coverage's planFeatures.hasMnrDental flag
  if (coverageType === CoverageType.Dental) {
    mnrHasDental = coverages
      .filter(c => c.coverageType === CoverageType.Medical)
      .some(c => CoverageStatus.Active === c.planPeriod.status && c.planFeatures.hasMnrDental);
  }
  return (
    coverages.filter(c => c.coverageType === coverageType).some(c => CoverageStatus.Active === c.planPeriod.status) ||
    mnrHasDental
  );
}

export function hasActiveOrFutureCoverageForCoverageType(
  coverageType: CoverageType,
  coverages: IPlanCoverage[],
): boolean {
  return coverages
    .filter(c => c.coverageType === coverageType)
    .some(c => CoverageStatus.Active === c.planPeriod.status || CoverageStatus.Future === c.planPeriod.status);
}

export function hasInstamed(currentUser: IProfileUser): boolean {
  return currentUser.relationshipType === RelationshipType.Subscriber;
}

const hasAFutureCoverageForCoverageType = (coverageType: CoverageType, coverages: IPlanCoverage[]): boolean => {
  return (coverages || [])
    .filter(c => c.coverageType === coverageType)
    .some(c => CoverageStatus.Future === c.planPeriod.status);
};

const hasAnActiveCoverageForCoverageType = (
  coverageType: CoverageType,
  coverages: IPlanCoverage[],
  includeFuture?: boolean,
): boolean => {
  if (includeFuture) {
    return (
      hasActiveCoverageForCoverageType(coverageType, coverages) ||
      hasAFutureCoverageForCoverageType(coverageType, coverages)
    );
  }
  return hasActiveCoverageForCoverageType(coverageType, coverages);
};

export const hasFindCare = (currentUser: IProfileUser): boolean => {
  if (currentUser.lineOfBusiness === LineOfBusiness.MR) {
    return (
      currentUser.memberFeatures.fpcEligible &&
      currentUser.planCoverages.some(cov => {
        return (
          cov.planPeriod.status !== CoverageStatus.Termed &&
          (cov.coverageTypeCode === CoverageTypeCode.MA ||
            cov.coverageTypeCode === CoverageTypeCode.MAPD ||
            cov.coverageTypeCode === CoverageTypeCode.PDP ||
            cov.coverageTypeCode === CoverageTypeCode.MedicareSupplement)
        );
      })
    );
  } else {
    return (
      currentUser.memberFeatures.fpcEligible &&
      (hasAnActiveCoverageForCoverageType(CoverageType.Medical, currentUser.planCoverages, true) ||
        hasAnActiveCoverageForCoverageType(CoverageType.Rx, currentUser.planCoverages, true))
    );
  }
};

export function hasLedgerAccess(currentUser: IProfileUser): boolean {
  return (
    (currentUser.relationshipType === RelationshipType.Subscriber ||
      currentUser.relationshipType === RelationshipType.DomesticPartner) &&
    currentUser.lineOfBusiness !== LineOfBusiness.MR
  );
}

export function hasCoverageStatus(plans: IPlanCoverage[], coverageStatus: CoverageStatus[]): IPlanCoverage[] {
  return plans.filter(plan => coverageStatus.includes(plan.planPeriod.status));
}

export function hasCoverageTypeCode(plans: IPlanCoverage[], coverageTypeCodes: CoverageTypeCode[]): IPlanCoverage[] {
  return plans.filter(plan => coverageTypeCodes.includes(plan.coverageTypeCode));
}

export function getCoverageTypeCode(plans: IPlanCoverage[], coverageTypeCodes: CoverageTypeCode[]): CoverageTypeCode {
  return plans.find(plan => coverageTypeCodes.includes(plan.coverageTypeCode)).coverageTypeCode;
}

export function isRxCarveOut(coverage: IPlanCoverage): boolean {
  return (
    coverage.coverageType === CoverageType.Rx &&
    typeof coverage.rxCoverageInfo !== 'undefined' &&
    coverage.rxCoverageInfo.isExternallyManaged
  );
}

export function hasCoverageStatusAndCoverageTypeCode(
  plans: IPlanCoverage[],
  coverageStatus: CoverageStatus[],
  coverageTypeCodes: CoverageTypeCode[],
): boolean {
  const plansWithCoverageStatus = hasCoverageStatus(plans, coverageStatus);

  return hasCoverageTypeCode(plansWithCoverageStatus, coverageTypeCodes).length > 0;
}

export function isMrUserWithGivenSnpType(currentUser: IProfileUser, snpType: MrSnpType): boolean {
  return (
    currentUser.lineOfBusiness === LineOfBusiness.MR &&
    currentUser.planCoverages.some(coverage => coverage.snpType === snpType)
  );
}

export function isTermedForCoverageType(coverageType: CoverageType, coverages: IPlanCoverage[]): boolean | undefined {
  if (coverages.some(c => c.coverageType === coverageType)) {
    return getCoverage(coverageType, coverages).planPeriod.status === CoverageStatus.Termed;
  }
}

export function hasActiveCoverages(coverages?: IPlanCoverage[]): boolean {
  return coverages ? coverages.some(cov => cov.planPeriod.status === CoverageStatus.Active) : false;
}

export function isPreEffective(coverages: IPlanCoverage[]): boolean | undefined {
  return coverages.every(c => c.planPeriod.status === CoverageStatus.Future);
}

export function isTermed(coverages: IPlanCoverage[]): boolean | undefined {
  return coverages.every(c => c.planPeriod.status === CoverageStatus.Termed);
}

export function isEffective(coverages: IPlanCoverage[]): boolean | undefined {
  return coverages.every(c => c.planPeriod.status === CoverageStatus.Active);
}

export function isMedicalEffective(coverages: IPlanCoverage[]): boolean | undefined {
  return !!findMedicalEffectiveCoverage(coverages);
}

export function findMedicalEffectiveCoverage(coverages: IPlanCoverage[]): IPlanCoverage | undefined {
  return coverages.find(
    plan => plan.coverageType === CoverageType.Medical && plan.planPeriod.status === CoverageStatus.Active,
  );
}

export function hasTermedMRCoveragePastYear(coverages: CoverageTypeCode[], profileUser: IProfileUser): boolean {
  const isMR = profileUser.lineOfBusiness === LineOfBusiness.MR;
  const isOneYearBefore = subYears(new Date(), 1);
  const hasTermedRelevantCoverage = profileUser.planCoverages.some(
    cov =>
      coverages.indexOf(cov.coverageTypeCode) > -1 &&
      cov.planPeriod.status === CoverageStatus.Termed &&
      isSameDayOrAfter(cov.planPeriod.endDate, isOneYearBefore),
  );
  return isMR && hasTermedRelevantCoverage;
}

export const shouldHidePcp = (selectedUser: IProfileUser, config: IConfig): boolean => {
  const membershipCategory: MembershipCategory = selectedUser.membershipCategory;
  const primaryPolicyNumber: string = selectedUser.primaryPolicyNumber;
  const policiesToHide = config.ARCADE_WEB_HIDE_PCP_POLICIES.split(',');
  return (
    membershipCategory === MembershipCategory.EXPATRIATE ||
    membershipCategory === MembershipCategory.EMPIRE ||
    policiesToHide.includes(primaryPolicyNumber)
  );
};

export function getShowMrRXAccumulators(planCoverage: IPlanCoverage, config: IConfig): boolean {
  const rxExclusionListPolicyNumbers = isSameYearOrAfter(new Date(Date.now()), new Date(2023, 0, 1))
    ? splitConfig(config.ARCADE_WEB_MR_RX_SUPPRESSION_2023_OVERRIDE_GROUP_NUMBERS)
    : splitConfig(config.ARCADE_WEB_MR_RX_SUPPRESSION_2021_OVERRIDE_GROUP_NUMBERS);
  return !planCoverage.planFeatures.lisLevel && !rxExclusionListPolicyNumbers.includes(planCoverage.policyNumber);
}

export function getShowMrMedicalAccumulators(planCoverage: IPlanCoverage, config: IConfig): boolean {
  const medicalExclusionListPolicyNumbers = splitConfig(
    config.ARCADE_WEB_MR_SPENDING_COST_SUMMARY_SUPPRESSION_POLICIES,
  );
  const medicalExclusionListProductCodes = splitConfig(config.ARCADE_WEB_MR_SPENDING_COST_SUMMARY_SUPPRESSION_PRODUCTS);
  return !(
    medicalExclusionListPolicyNumbers.includes(planCoverage.policyNumber) &&
    medicalExclusionListProductCodes.includes(planCoverage.additionalCoverageInfo.productCode)
  );
}

export function getFilteredMrPlanCoveragesForAccumulators(
  planCoverages: IPlanCoverage[],
  config: IConfig,
): IPlanCoverage[] {
  return planCoverages
    .filter(planCoverage => planCoverage.planPeriod.status === CoverageStatus.Active)
    .filter(planCoverage => !planCoverage.planFeatures?.isMedicaidDualEligible)
    .filter(planCoverage => {
      if (planCoverage.coverageTypeCode === CoverageTypeCode.PDP) {
        return getShowMrRXAccumulators(planCoverage, config);
      }
      if (planCoverage.coverageTypeCode === CoverageTypeCode.MAPD) {
        return getShowMrRXAccumulators(planCoverage, config) || getShowMrMedicalAccumulators(planCoverage, config);
      }
      return getShowMrMedicalAccumulators(planCoverage, config);
    });
}

export function isUserFromStates(user: IProfileUser, states: State[]): boolean {
  return states.includes(user.userInfo.state);
}

export const hasCanopyHealthPlan = (currentSelectedProfile: IProfileUser): boolean => {
  return currentSelectedProfile.planCoverages.some(
    plan =>
      plan.additionalCoverageInfo.marketType === MarketType.canopyHealth &&
      plan.additionalCoverageInfo.marketNumber === MarketNumber.canopyHealth,
  );
};

export const hasWalmartPlan = (selectedProfile: IProfileUser): boolean => {
  return selectedProfile.planCoverages?.some(plan => plan.additionalCoverageInfo?.productCode === 'H8748026000');
};

/**
 * Checks if the selected user has ANY physician with the matching provider id substring and the specified networkTypeCode
 * Currently only providers with networkTypeCode of VA are allowed
 * @param currentSelectedProfile: Currently selected user
 * @param primaryCarePerMember: Primary care providers to check against
 */
export const hasCanopyPhysician = (
  currentSelectedProfile: IProfileUser,
  primaryCarePerMember?: IPrimaryCarePerMember,
): boolean => {
  const config = getConfig();
  const qualifyingNetworkTypeCodes = config?.ARCADE_WEB_EI_QUICKLINKS_CANOPY_NETWORK_TYPE_CODES?.split(',');
  return primaryCarePerMember?.[currentSelectedProfile.dependentSeqNum]?.primaryCarePhysicians?.some(
    physician =>
      qualifyingNetworkTypeCodes?.some(code => physician?.networkTypeCode?.code === code) &&
      config?.ARCADE_WEB_CANOPY_HEALTH_SUB_PROVIDER_ID?.includes(physician?.providerId?.substring(0, 6)),
  );
};

export function getMemberStatus(user: IProfileUser): CoverageStatus | string {
  // ARC-4174: For M&R if any coverage is active then user is considered active, for E&I use medical coverage status
  // ARC-5482: If there is a future coverage but not currently any active ones we consider the user as pre-effective
  if (user.lineOfBusiness === LineOfBusiness.MR) {
    if (user.planCoverages.some(c => c.planPeriod.status === CoverageStatus.Active)) {
      return CoverageStatus.Active;
    } else if (user.planCoverages.some(c => c.planPeriod.status === CoverageStatus.Future)) {
      return 'Pre-Effective';
    } else {
      return CoverageStatus.Termed;
    }
  } else {
    let medicalCoverage;
    for (const coverage of user.planCoverages) {
      if (coverage.coverageType === CoverageType.Medical) {
        medicalCoverage = coverage;
      }
    }

    return medicalCoverage ? medicalCoverage.planPeriod.status : undefined;
  }
}

export const coverageStartDate = (coverages: IPlanCoverage[], coverageType: CoverageType): string | undefined => {
  const plan = coverages.find(c => c.coverageType === coverageType);
  return plan ? format(plan.planPeriod.startDate, 'M/d/yyyy') : undefined;
};

export const formattedPreEffectiveCoverageStartDate = (coverages: IPlanCoverage[]): string => {
  const futureCoverage = coverages?.find(c => c.planPeriod.status === CoverageStatus.Future)?.planPeriod?.startDate;
  const formattedStartDate = futureCoverage && format(futureCoverage, 'yyyy');
  return formattedStartDate;
};

export const hasShipCoverage = (coverages: IPlanCoverage[]): boolean => {
  return coverages.some(c => c.planFeatures.programType === ProgramType.Ship);
};

export const isPremiumPaymentsEligible = (currentUser: IProfileUser, coverages: IPlanCoverage[]): boolean => {
  const config = getConfig();
  const csPremiumPaymentPrimaryEligibleIds =
    config.ARCADE_WEB_CS_PREMIUM_PAYMENT_PRIMARY_ELIGIBLE_IDS?.split(',') || [];

  switch (currentUser.lineOfBusiness) {
    case LineOfBusiness.MR:
      return currentUser.memberFeatures.premiumPaymentEligible && !isTermed(coverages);
    case LineOfBusiness.CS:
      return csPremiumPaymentPrimaryEligibleIds.includes(currentUser.primaryPolicyNumber) && !isTermed(coverages);
    default:
      return false;
  }
};

export function shouldShowRxPrescriptions(coverages: IPlanCoverage[]): boolean | undefined {
  const activeRx = hasAdditionalActiveCoverageForCoverageType(CoverageType.Rx, coverages);
  const rxCoverage = getCoverage(CoverageType.Rx, coverages);
  return activeRx || rxCoverage?.rxCoverageInfo?.coverageType === RxCoverageType.ORX;
}

export function isUNETMember(currentUser: IProfileUser): boolean {
  return currentUser?.sourceSystem === SourceSystem.UNET;
}

export function isUSPMember(currentUser: IProfileUser): boolean {
  return currentUser?.sourceSystem === SourceSystem.USP;
}

export function isGroupRetiree(currentUser: IProfileUser): boolean {
  return currentUser?.membershipCategory === MembershipCategory.RETIREE;
}

// This function is to check if coverage type code for mr group retiree is MA/MAPD
export const checkMnrMaMapdGroup = (currentProfile: IProfileUser, planBenefits: IPlanBenefits): boolean => {
  if (planBenefits) {
    return (
      currentProfile.membershipCategory === MembershipCategory.RETIREE &&
      planBenefits.benefits.some(
        coverage => [CoverageTypeCode.MA, CoverageTypeCode.MAPD].indexOf(coverage.coverageTypeCode) >= 0,
      )
    );
  }
  return false;
};

export const checkMnrMaMapd = (currentProfile: IProfileUser, coverage: ICoverageSection): boolean => {
  if (currentProfile.lineOfBusiness === LineOfBusiness.MR) {
    return [CoverageTypeCode.MA, CoverageTypeCode.MAPD].includes(coverage.coverageTypeCode);
  }
  return false;
};

export const isTwoMonthsAwayFrom65 = (currentProfile: IProfileUser): boolean => {
  if (!currentProfile.userInfo?.dob) return false;
  const dob = new Date(currentProfile.userInfo.dob);

  const currentDate = new Date();
  // Calculate the start date (beginning of the month, two months before 65)
  const startDate = new Date(Date.UTC(currentDate.getFullYear() - 65, currentDate.getMonth() - 2, 1));
  // Calculate the end date (end of the month, two months after 65)
  const endDate = new Date(Date.UTC(currentDate.getFullYear() - 65, currentDate.getMonth() + 3, 0));

  return dob >= startDate && dob <= endDate;
};

export const isPast65And3Months = (currentProfile: IProfileUser): boolean => {
  if (!currentProfile.userInfo?.dob) return false;
  const dob = new Date(currentProfile.userInfo.dob + 'T00:00:00');

  const currentDate = new Date();
  const [year, month] = [currentDate.getFullYear(), currentDate.getMonth()];
  // Calculates 65 and 3 months should be the start date
  // for the whole month of the 65th birthday and 3 months
  const startDate = new Date(year - 65, month - 2, 1);

  return dob < startDate;
};
