import {
  CoverageStatus,
  CoverageType,
  CoverageTypeCode,
  ICoverageTimePeriod,
  IFullName,
  IResponse,
  RelationshipType,
} from '../api.interfaces';
import { AccountType } from '../ledger/ledger.interfaces';

export enum CsPrimaryCustomerId {
  CAMCMP = '0CAMCMP',
  KSKCCH = '0KSKCCH',
  KSKCMD = '0KSKCMD',
  MAUHCSCO = 'MAUHCSCO',
  NYCHP = 'NYCHP',
  NYEPP = 'NYEPP',
  OHMMEP = '0OHMMEP',
  OHPHCP = '0OHPHCP',
  PACHIP = '0PACHIP',
  MNHCP = 'MNHCP',
}

// SNP standing for Special Needs Plans are made up of several different kinds of plans
export enum MrSnpType {
  CSNP = 'CSNP', // Institutional special needs program
  DSNP = 'DSNP', // Dual special needs program (both Medicare and Medicaid)
  ISNP = 'ISNP', // Chronic special needs program
  NonSnp = 'NON-SNP', // not SNP
}

export enum Product {
  BEHAVIORAL = 'BEHAVIORAL',
  ClickToChat = 'CLICKTOCHAT',
  DVCN = 'DVCN',
  EasyConnect = 'EASYCONNECT',
  IHR = 'IHR',
  LAWW = 'LAWW',
  MemberSupport = 'MEMBERSUPPORT',
  NurseChat = 'NURSECHAT',
  NurseLine = 'NURSELINE',
  RallyConnect = 'RALLYCONNECT',
  RallyEngage = 'RALLYENGAGE',
  RX = 'RX',
  SecureMessage = 'SECUREMESSAGE',
  TwentyFourSevenNurseLine = '24/7 Nurse Line',
  Unknown = 'UNKNOWN',
  W500 = 'W500',
}

export enum RxCoverageType {
  CRX = 'CRX',
  ORX = 'ORX',
  RX = 'RX',
}

export enum RxCarveOutProvider {
  Caremark = 'CAREMARK',
  ExpressScripts = 'EXPRESS_SCRIPTS',
  Optum = 'OPTUM',
  Walgreens = 'WALGREENS',
}

export enum PcpMemberState {
  NoPcpSelected = 'NO_PCP_SELECTED',
  PcpChangeInProgress = 'PCP_CHANGE_IN_PROGRESS',
  PcpSelected = 'PCP_SELECTED',
}

export enum LineOfBusiness {
  CS = 'C&S',
  EI = 'E&I',
  MR = 'M&R',
}

export enum MembershipCategory {
  AARP = 'AARP',
  EMPIRE = 'EMPIRE',
  EXPATRIATE = 'EXPATRIATE',
  JPMC = 'JPMC',
  MEDICA = 'MEDICA',
  OXFORD = 'OXFORD',
  PCP = 'PCP',
  EXCHANGE = 'EXCHANGE',
  PUBLIC_EXCHANGE = 'PUBLIC_EXCHANGE',
  RETIREE = 'RETIREE',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
  TEXAS_ERS = 'TEXAS_ERS',
  TUFTS = 'TUFTS',
  UHC = 'UHC',
  UHC_MEDICA = 'UHC_MEDICA',
  UHC_WEST = 'UHC_WEST',
  UHG_LEVEL_FUNDED = 'UHG_LEVEL_FUNDED',
  HEALTH_PLAN = 'HEALTH_PLAN',
}

export enum Aco {
  CDP = 'CDP',
  DP = 'DP',
}
export type Acos = Aco[];

export enum FundingType {
  AdministrativeServices = 'ADMINISTRATIVE_SERVICES',
  FullyInsured = 'FULLY_INSURED',
  Group = 'GROUP',
  Individual = 'INDIVIDUAL',
  MMP = 'MODIFIED_MINIMUM_PREMIUM',
}

export enum ProgramType {
  Federal = 'FEDERAL',
  Ship = 'SHIP',
  MEDICAID = 'MEDICAID',
}

export enum Gender {
  female = 'FEMALE',
  male = 'MALE',
  unknown = 'UNKNOWN',
}

export enum FpcPcpType {
  Behavioral = 'BEHAVIORAL',
  Dental = 'DENTAL',
  Medical = 'MEDICAL',
}

export enum PcpType {
  Dental = 'DENTAL',
  Facility = 'FACILITY',
  Group = 'GROUP',
  Physician = 'PHYSICIAN',
  IPA = 'IPA',
}

export enum State {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum YesNo {
  Y = 'Y',
  N = 'N',
}

export enum EftAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

export enum EftPlanType {
  M = 'M',
  F = 'F',
  A = 'A',
  N = 'N',
}

export enum PhoneType {
  phone = 'phone',
  fax = 'fax',
}

export enum LisLevel {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
}

export enum PrimaryPolicyNumber {
  HWCCRLO = '0023111',
  NCCCERMA = '0046000',
  INHCC = 'INHCC',
  TXSTK = 'TXSTK',
  MSCAN = 'MSCAN',
}

export enum MarketNumber {
  canopyHealth = '0004422',
}

export enum MarketType {
  canopyHealth = '95',
}

export enum PolarisFormsType {
  UHG = 'UHG',
  Oxford = 'OXFORD',
}

export enum VirtualExperienceType {
  OVC = 'OVC',
}

export enum SourceSystem {
  USP = 'USP',
  UNET = 'UNET',
  NICE = 'NICE',
}

export enum AccountCardType {
  DualCard = 'DUALCARD',
  FlexCard = 'FLEXCARD',
  UCard = 'UCARD',
  Unknown = 'UNKNOWN',
}

export enum AccountCardStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Unknown = 'UNKNOWN',
  Waiting = 'WAITING',
}

export interface IProfileUserInfo extends IFullName {
  dob: string;
  age: number;
  gender: Gender;
  zipCode?: string;
  primaryCustomerId?: string;
  state?: State;
  address1?: string;
  city?: string;
  primaryPhoneNumber?: string;
  mailingAddress?: string;
}

export interface IUserAddress {
  formattedAddress1?: string;
  formattedCity?: string;
  formattedState?: string;
  zipCode?: string;
  fullFormattedAddress?: string;
}

export interface IMemberId {
  id: string;
}

export interface ICoverageId {
  [key: string]: string;
}

export interface IPlanCoverage {
  additionalCoverageInfo: IAdditionalCoverageInfo;
  additionalCoverageTypes: CoverageType[];
  billSummaryKeyEncrypted?: string;
  coverageType: CoverageType;
  coverageTypeCode?: CoverageTypeCode;
  memberId: IMemberId;
  planFeatures: IPlanFeatures;
  planKey: string;
  planPeriod: ICoverageTimePeriod;
  policyNumber: string;
  rxCoverageInfo?: IRxCoverageInfo;
  snpType?: MrSnpType;
  isHDHP?: boolean;
  groupNumber?: string;
  coverageId?: ICoverageId;
}

export interface IDependent {
  dentalSequenceNumber?: string;
  sequenceNumber: string;
}

export interface IDependentAndCoverageTypes {
  dependent: IDependent;
  claimTypes: CoverageType[];
}

export interface IHealthcareCoveragesResponse extends IResponse<IDependentAndCoverageTypes[]> {}

export interface IAdditionalCoverageInfo {
  customerName?: string;
  aco?: Aco;
  marketType?: string;
  marketNumber?: string;
  obligorId?: string;
  productCode?: string;
  sharedArrangementCode?: string;
  situsState?: string;
}

export interface IPlanFeatures {
  fundingArrangementType?: FundingType;
  hasIdCard: boolean;
  hasPcpReferrals: boolean;
  hsaEligible: boolean;
  isDVCN: boolean; // Virtual Visit Eligibility
  isEmergentWrap: boolean;
  isEssentialHealthBenefitsPlan: boolean;
  isIndividualIdCard: boolean;
  virtualExperienceType?: string;
  virtualPCPAssignedInd?: boolean;
  isMedicaidDualEligible?: boolean;
  isSupplemental: boolean;
  fpcEligible: boolean;
  lisLevel?: LisLevel;
  notificationMessaging?: boolean;
  pcdEligible: boolean;
  pcpEligible: boolean;
  pcpEligibilityIndicator?: string;
  phiRestricted: boolean;
  planId?: string;
  premiumPaymentEligible: boolean;
  programType?: ProgramType;
  /**
   * A field indicating that a plan is a High Deductible Health Plan.  This will be set only for E&I and M&R members
   */
  isHDHP?: boolean;
  hasMnrDental?: boolean;
}

export interface IRxCoverageInfo {
  coverageType: RxCoverageType;
  carveOutProvider?: RxCarveOutProvider;
  isExternallyManaged: boolean;
}

export interface IFinancialAccount {
  accountType: AccountType;
  policyNumber: string;
  accountId: string;
}

export interface IArcadeClient {
  displayName: string;
  logo?: string;
  hasCustomContent: boolean;
  clientId: string;
  configId: string;
}

export interface IProfileUser {
  rallyId?: string;
  personId?: string;
  userInfo: IProfileUserInfo;
  lineOfBusiness: LineOfBusiness;
  membershipCategory?: MembershipCategory;
  dependentSeqNum: string;
  relationshipType: RelationshipType;
  planCoverages: IPlanCoverage[];
  financialAccounts: IFinancialAccount[];
  memberFeatures: IMemberFeatures;
  pvrc?: IPlanVariations;
  primaryPolicyNumber: string;
  acos?: Acos;
  sourceSystem?: SourceSystem;
}

export interface ICommunicationPreferenceStep {
  stepNeeded: boolean;
  communicationEmail?: string;
}

export interface IOnboardingSteps {
  communicationPreference: ICommunicationPreferenceStep;
}

export interface IProfile {
  rallyId: string;
  currentUser: IProfileUser;
  dependents: IProfileUser[];
  mobilePromoEligible: boolean;
  clientInfo?: IArcadeClient;
  onboardingSteps?: IOnboardingSteps;
}

export interface IContactHours {
  startTime: string;
  endTime: string;
  timeZone: string;
  frequencyValue?: string;
  frequency?: string;
  isTwentyFourSeven: boolean;
}

export interface IContactInfo {
  hours?: IContactHours[];
  phoneNum?: string;
  phoneExt?: string;
  url?: string;
  reciprocityId?: string;
}

export interface IMemberFeatures {
  activateEligible: boolean;
  fpcEligible: boolean;
  gated: boolean;
  hsaEligible: boolean;
  hasPcpReferrals: boolean; // PCP referrals eligibility
  hasVirtualVisitsIneligibleProduct?: boolean; // temporary suppression of MR VV in PCP strip
  hospitalDirectoryEligible?: boolean; // true for active/pre-effective MR MedSupp members with eligible plan codes
  isDsnp?: boolean; // only used for C&S LOB
  isPolaris?: boolean;
  isPrime?: boolean;
  isTufts?: boolean;
  pcdEligible?: boolean;
  pcpEligible: boolean;
  polarisFormsType?: PolarisFormsType;
  premiumPaymentEligible: boolean;
  selectPcpEligible: boolean;
  suppressVirtualVisits?: boolean;
  viewRestricted: boolean;
}

export interface IProduct {
  contactInformation?: IContactInfo;
  planInformation?: IPlanInformation;
  productId: Product;
  miscellaneousInfo?: IMiscellaneousInfo;
  fpcEligible?: boolean;
  medicalPhoneNumber?: string;
}

export interface IPlanInformation {
  networkId?: string;
}

export interface IMiscellaneousInfo {
  sourceSystem?: string;
  client?: string;
  consortium?: string;
  uniqueProduct?: string;
  dedicatedQueue?: string;
  offshoreRestricted?: string;
}

export type IFeatures = string[] | undefined;

export interface IProducts {
  rallyConnect: IProduct;
  rallyEngage: IProduct;
  nurseChat: IProduct;
  nurseLine: IProduct;
  easyConnect: IProduct;
  clickToChat: IProduct;
  secureMessage: IProduct;
  medicalSupport: IProduct;
  technicalSupport: IProduct;
  rx: IProduct;
  individualHealthRecord: IProduct;
  liveAndWorkWell: IProduct;
  nurseHealthLine: IProduct;
  silverSneakers: IProduct;
  visionDiscount: IProduct;
  atYourBest: IProduct;
  dime: IProduct;
  dime2: IProduct;
  providerDiscountNetwork: IProduct;
  myCarePath: IProduct;
  dental: IProduct;
  core_mpe_rallyRx: IProduct;
  core_mpe_ucard: IProduct;
  behavioral: IProduct;
  otc: IProduct;
  otcFirstLine: IProduct;
  aarpSmartDriverStates: IProduct;
  aarpSmarDriverTerritories: IProduct;
  aarpStayingSharp: IProduct;
  aarpStayingSharpNonRA: IProduct;
  aarpVision: IProduct;
  dentalDiscount: IProduct;
  hearingCareProgram: IProduct;
  renewActive: IProduct;
  silverSneakersV2: IProduct;
  twentyFourSevenNurseLine: IProduct;
  features: IFeatures;
  vision: IProduct;
}

export interface ICoverageInfo {
  coverageTypes: Partial<Record<CoverageType, boolean>>;
  coverageTypeCodes: Partial<Record<CoverageTypeCode, boolean>>;
  numCoverages: number;
}

export interface IProfileResponse extends IResponse<IProfile> {}

export interface IProductsResponse extends IResponse<{ products: IProducts; features?: IFeatures }> {}

export interface ICspaRewardsResponse extends IResponse<{ data: ICspaRewards }> {}

export interface IProfileInfoForRallyPayResponse extends IResponse<{ encryptedProfileInfo: string }> {}

export interface IPrimaryCareFpcResponse extends IResponse<IPrimaryCareFpc> {}

export interface IPrimaryCareResponse extends IResponse<{ perMemberResults: IPrimaryCarePerMember }> {}

export interface IPrimaryCareFpc {
  lockedIn?: boolean;
  dependentId: string;
  doctorDetails: IPrimaryCareFpcPhysician[];
}

export interface IPrimaryCarePerMember {
  [depSeqNum: string]: IPrimaryCareUserInfo;
}

export interface IPrimaryCareUserInfo {
  memberState: PcpMemberState;
  lockedIn?: boolean;
  primaryCarePhysicians: IPrimaryCarePhysician[];
}

export interface IPhysician extends IFullName {
  providerId?: string;
  suppressPhysicianLink?: boolean; // Used for suppressing Provider Referrals Links
}

export interface IAddress {
  city: string;
  county?: string;
  state: State;
  street1: string;
  street2?: string;
  zipCode: string;
}

export interface IPhone {
  number: string;
  phoneType: string;
}

export interface IPrimaryCareFpcPhysician extends IPhysician {
  address: IAddress;
  degree?: string;
  pcpId: string;
  pcpType: FpcPcpType;
  phones: IPhone[];
  status: CoverageStatus;
}

export interface IPrimaryCarePhysician extends IPhysician {
  addressSequenceNumber?: string;
  effectiveDate: string;
  pcpType: PcpType;
  status: CoverageStatus;
  networkTypeCode?: INetworkTypeCode;
}

export interface INetworkTypeCode {
  desc: string;
  code: string;
}

export interface IReferralsResponse extends IResponse<{ perMemberReferrals: IReferralsPerMember }> {}

export interface IReferralsPerMember {
  [depSeqNum: string]: IReferralsUserInfo;
}

export interface IReferralsUserInfo {
  totalNumberOfActiveReferrals: number;
  referrals: IReferralInfo[];
  error?: string;
}

export interface IReferralInfo {
  approvedUnitCount?: string;
  effectiveDateRange?: ITimePeriodDateFNS;
  patientName?: string;
  referralNumber: string;
  referralStatus: CoverageStatus;
  referrer?: IPhysician;
  remainingUnitCount?: string;
  servicer?: IPhysician;
}
// TODO: remove this after migration to dateFNS
export interface ITimePeriodDateFNS {
  startDate: string;
  endDate?: string;
}
export interface IPlanVariations {
  planVariationCode?: string;
  reportingCode?: string;
}

export interface ICommunicationPreferences {
  hasAgreedToPaperlessCommunication?: boolean;
  paperlessEmailAddress?: string;
}

export interface ICommunicationPreferencesResponse extends IResponse<ICommunicationPreferences> {}

export interface IProviderVideo {
  groupIdEncrypted: string;
  providerVideoKey: string;
  subscriberIdEncrypted: string;
}

export interface IProviderVideoKeyResponse extends IResponse<IProviderVideo> {}

export interface IMarkPaid {
  showDisclaimer: boolean;
}

export interface IClaimsPreferences {
  markAsPaid: IMarkPaid;
}

export interface IAutoSubmissionPreferences {
  autoSubmissionIndicator: YesNo;
  accountDetailTypeCode: AccountType;
  startDate: string;
  stopDate: string;
  isAutoPaymentEligible: boolean;
}

export interface IEftPreferences {
  prefStatusCode?: YesNo;
  accountNumber?: string;
  accountType?: EftAccountType;
  bankName?: string;
  eftHierarchyType?: string;
  employerPlanType?: EftPlanType;
  planType?: EftPlanType;
  routingNumber?: string;
}

export interface IFinancialPreferences {
  autoSubmissionPreferences: IAutoSubmissionPreferences[];
  eftPreferences?: IEftPreferences;
}

export interface IFinancialPreferencesResponse extends IResponse<IFinancialPreferences> {}

export interface IClaimsPreferencesResponse extends IResponse<IClaimsPreferences> {}

export interface IInstamedMobileToken {
  ssoPayload: string;
}

export interface IInstamedMobileTokenResponse extends IResponse<IInstamedMobileToken> {}

export interface IAnnexJWT {
  data: {
    annex: string;
  };
}

export interface IAnnexJWTResponse extends IResponse<IAnnexJWT> {}

export interface IToken {
  token: string;
}

export interface IGenoaTokenResponse extends IResponse<IToken> {}

export interface IOrderCovidTestKitTokenResponse extends IResponse<IToken> {}

export interface IOrderCovidTestKitInfo {
  token?: string;
  uid?: string;
}

/**
 * Correlates with Arcade-Profile ArcadeBenefitsCard model.
 */
export interface IAccountCard {
  cardId: string;
  cardType: AccountCardType;
  status: AccountCardStatus;
  planYear: string;
}

export interface ICspaAuthorization {
  capability?: string;
  features?: string[];
  coverageStatus?: string;
}

export interface ICspaRewards {
  authorization?: ICspaAuthorization[];
}

export interface IAccountCardsResponse extends IResponse<IAccountCard[]> {}

export interface IUhcRewardsResponse extends IResponse<boolean> {}

export interface IMedicareNudgeEligibilityResponse extends IResponse<boolean> {}
