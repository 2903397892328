import {
  getClaimsPreferencesError,
  getClaimsPreferencesLoading,
  getClaimsPreferencesSuccess,
  getCommunicationPreferencesLoading,
  getCommunicationPreferencesSuccess,
  getCommunicationPreferencesError,
  getProductsError,
  getProductsLoading,
  getProductsSuccess,
  getProfileError,
  getProfileLoading,
  getProfileSuccess,
  getReferralsError,
  getReferralsLoading,
  getReferralsSuccess,
  getHealthcareCoveragesLoading,
  getHealthcareCoveragesSuccess,
  getHealthcareCoveragesError,
  getPrimaryCareError,
  getPrimaryCareLoading,
  getPrimaryCareSuccess,
  getFpcPrimaryCareLoading,
  getFpcPrimaryCareSuccess,
  getFpcPrimaryCareError,
  getFinancialPreferencesError,
  getFinancialPreferencesLoading,
  getFinancialPreferencesSuccess,
  setClaimsPreferencesSuccess,
  getMedicareNudgeEligibilitySuccess,
  getMedicareNudgeEligibilityError,
  getProviderVideoKeySuccess,
  getProfileInfoForRallyPayError,
  getProfileInfoForRallyPayLoading,
  getProfileInfoForRallyPaySuccess,
  changeSelectedProfile,
  getOrderCovidTestKitLoading,
  setOrderCovidTestKitSuccess,
  setOrderCovidTestKitError,
  getAccountCardsLoading,
  getAccountCardsSuccess,
  getAccountCardsError,
  getUhcRewardsLoading,
  getUhcRewardsSuccess,
  getUhcRewardsError,
  getMedicareNudgeEligibilityLoading,
  getCspaRewardsSuccess,
  getCspaRewardsLoading,
  getCspaRewardsError,
} from 'scripts/reducers/profile-service-reducer';
import { ProfileApi } from 'scripts/api/profile/profile-api';
import {
  IClaimsPreferences,
  ICommunicationPreferences,
  IEftPreferences,
  IProfileUser,
  IAccountCard,
} from 'scripts/api/profile/profile.interfaces';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';
import {
  selectedUser as selectedUserSelector,
  selectPrimaryCareData as selectPrimaryCareDataSelector,
  selectFpcPrimaryCareData as selectFpcPrimaryCareDataSelector,
} from 'scripts/selectors/profile-service-selectors';
import { selectMembershipCategory } from 'scripts/selectors/population-selectors';
import { selectBenefitsData } from 'scripts/selectors/plans-service-selectors';
import { getBenefits } from './plans-service-thunks';
import { addLoadingBarRequest, removeLoadingBarRequest } from './loading-bar-thunks';
import { handleAuthenticationError } from './user-service-thunks';
import { setAAPcpAssignStatus } from 'scripts/util/tracking/adobe-analytics';
import { IFeatureFlags } from 'scripts/util/constants/environment.interfaces';
import { setShowActivateDynamicOnboardingPaperless, setShowHealthTopicsModal } from 'scripts/reducers/app-reducer';
import { IClaim, IHealthcareClaimDetails } from 'scripts/api/claims/claims.interfaces';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { getConfig } from 'scripts/util/config/config';

/**
 *  Importing the constant from the Activate Mini-Modal causes an error on app initialization, so we're going to
 *  redefine it here.
 */
export const activateUrgentCareModalDissmissedKey = 'ACTIVATE_URGENT_CARE_MODAL_DISMISSED';

function shouldShowActivateDynamicOnboardingPaperless(featureFlags: IFeatureFlags): boolean {
  return featureFlags.ARCADE_FEATURES_ACTIVATE_DYNAMIC_ONBOARDING_PAPERLESS;
}

function shouldShowHealthTopicsModal(featureFlags: IFeatureFlags): boolean {
  return featureFlags.ARCADE_FEATURES_ACTIVATE_HEALTH_TOPICS_MODAL === true;
}

export function getActivateProviderVideoKey(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const profile = selectedUserSelector.selectProfile(getState());
      const depSeqNum = selectedUserSelector.selectDependentSeqNum(getState());
      const params = { depSeqNum };
      const videoKey = await ProfileApi.getActivateProviderVideoKey(profile.rallyId, params);
      dispatch(getProviderVideoKeySuccess(videoKey.data));
    } catch (error) {
      console.warn(error);
    }
  };
}

export function getProfile(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(addLoadingBarRequest('profile'));
      dispatch(getProfileLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await ProfileApi.getProfile(rallyId, true);

      dispatch(setShowHealthTopicsModal(shouldShowHealthTopicsModal(getState().app.featureFlags)));
      dispatch(
        setShowActivateDynamicOnboardingPaperless(
          shouldShowActivateDynamicOnboardingPaperless(getState().app.featureFlags),
        ),
      );

      let selectedProfile = data.currentUser;
      let selectedProfileDependentSeqNum;
      try {
        selectedProfileDependentSeqNum = sessionStorage.getItem('arcade.selectedProfileDependentSeqNum');
      } catch (e) {
        dispatch(removeLoadingBarRequest('profile'));
        console.warn('Could not get selected profile dependent sequence number from session storage', e);
      }
      if (selectedProfileDependentSeqNum) {
        selectedProfile =
          data.dependents.find(d => d.dependentSeqNum === selectedProfileDependentSeqNum) || selectedProfile;
      }
      dispatch(getProfileSuccess({ profile: data, selectedProfile }));
    } catch (error) {
      dispatch(getProfileError());
      dispatch(handleAuthenticationError(error));
    } finally {
      dispatch(removeLoadingBarRequest('profile'));
    }
  };
}

/**
 * @param profile
 */
export function setCurrentProfile(profile: IProfileUser): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      sessionStorage.setItem('arcade.selectedProfileDependentSeqNum', profile.dependentSeqNum);
      dispatch(changeSelectedProfile(profile));
    } catch (error) {
      console.warn(`Error while setting current profile: ${error.toString()}`);
    }
  };
}

/**
 * Fetches orderCovidTestKitToken to receive the token to be able to SSO into Optum's store to order covid test kits.
 * - If the request fails we set the error UID (if exists) into the redux state. /n
 * - If the requests succeeds we set the token into Redux state for any React components that are watching for it.
 */
export function getOrderCovidTestKitToken(): ArcadeThunkAction<Promise<any | void>> {
  return async dispatch => {
    try {
      dispatch(getOrderCovidTestKitLoading());
      const {
        data: { token },
      } = await ProfileApi.getOrderCovidTestKitToken();
      dispatch(setOrderCovidTestKitSuccess({ token }));
    } catch (err) {
      const uid = err?.data?.correlationId ? err.data.correlationId : err?.statusText;
      dispatch(setOrderCovidTestKitError({ uid }));
    }
  };
}

export function getAccountCards(currentPlanYear: number): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      dispatch(getAccountCardsLoading());
      const { data, status } = await ProfileApi.getAccountCards();

      // If user does not have requested cards, we'll get a 204.
      const accountCardsMultiYear = status === 200 ? data : [];
      const accountCardsCurrentYear = currentPlanYear
        ? accountCardsMultiYear.filter((card: IAccountCard) => card.planYear.toString() === currentPlanYear.toString())
        : [];
      dispatch(getAccountCardsSuccess(accountCardsCurrentYear));
    } catch (err) {
      dispatch(getAccountCardsError());
    }
  };
}

export function getClaimsPreferences(): ArcadeThunkAction<Promise<IClaimsPreferences | void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getClaimsPreferencesLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await ProfileApi.getClaimsPreferences(rallyId);
      dispatch(getClaimsPreferencesSuccess(data));
      return data;
    } catch (error) {
      dispatch(getClaimsPreferencesError());
      return null;
    }
  };
}

export function setClaimsPreferences(preferences: IClaimsPreferences): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const rallyId = selectRallyId(getState());
      // update claims preferences in the BE
      await ProfileApi.setClaimsPreferences(rallyId, preferences);
      // update claims preferences in the Redux Store
      dispatch(setClaimsPreferencesSuccess(preferences));
    } catch (error) {
      console.warn(error);
    }
  };
}

export function getCommunicationPreferences(): ArcadeThunkAction<Promise<ICommunicationPreferences | void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getCommunicationPreferencesLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await ProfileApi.getCommunicationPreferences(rallyId);
      dispatch(getCommunicationPreferencesSuccess(data));
    } catch (error) {
      console.warn(error);
      dispatch(getCommunicationPreferencesError());
    }
  };
}

export function getFpcPrimaryCare(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getFpcPrimaryCareLoading());
      const rallyId = selectRallyId(getState());
      const config = getConfig();
      const lineOfBusiness = selectedUserSelector.selectLineOfBusiness(getState());
      const membershipCategory = selectMembershipCategory(getState());
      const profile = selectedUserSelector.selectProfile(getState());
      const selectPcpEligible = profile.memberFeatures.selectPcpEligible;
      const depSeqNum = selectedUserSelector.selectDependentSeqNum(getState());
      const { data } = await ProfileApi.getFpcPrimaryCare(
        rallyId,
        lineOfBusiness,
        membershipCategory,
        selectPcpEligible,
        depSeqNum,
      );
      setAAPcpAssignStatus(selectFeatureFlags(getState()))(
        profile,
        selectPrimaryCareDataSelector(getState()),
        data,
        config,
      );
      dispatch(getFpcPrimaryCareSuccess(data));
    } catch (error) {
      dispatch(getFpcPrimaryCareError());
    }
  };
}

export function getPrimaryCare(isPcdEligible?: boolean): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getPrimaryCareLoading());
      const rallyId = selectRallyId(getState());
      const config = getConfig();
      const {
        data: { perMemberResults },
      } = await ProfileApi.getPrimaryCare(rallyId, isPcdEligible);
      setAAPcpAssignStatus(selectFeatureFlags(getState()))(
        selectedUserSelector.selectProfile(getState()),
        perMemberResults,
        selectFpcPrimaryCareDataSelector(getState()),
        config,
      );
      dispatch(getPrimaryCareSuccess(perMemberResults));
    } catch (error) {
      dispatch(getPrimaryCareError());
    }
  };
}

export function getProducts(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const rallyId = selectRallyId(getState());
    if (!rallyId) {
      dispatch(getProductsError());
      return;
    }
    try {
      dispatch(getProductsLoading());
      const {
        data: { products, features },
      } = await ProfileApi.getProducts(rallyId);
      const newProducts = { ...products, features };
      dispatch(getProductsSuccess(newProducts));
    } catch (error) {
      dispatch(getProductsError());
    }
  };
}

// modify according to the ICspaRewards Interface

export function getCspaRewards(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const rallyId = selectRallyId(getState());
    if (!rallyId) {
      dispatch(getCspaRewardsError());
      return;
    }
    try {
      dispatch(getCspaRewardsLoading());
      const {
        data: { data },
      } = await ProfileApi.getCspaRewards(rallyId);
      const newCspaRewards = { ...data };
      dispatch(getCspaRewardsSuccess(newCspaRewards));
    } catch (error) {
      dispatch(getCspaRewardsError());
    }
  };
}

export function getReferrals(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getReferralsLoading());
      const rallyId = selectRallyId(getState());
      const {
        data: { perMemberReferrals },
      } = await ProfileApi.getReferrals(rallyId);
      dispatch(getReferralsSuccess(perMemberReferrals));
    } catch (error) {
      dispatch(getReferralsError());
    }
  };
}

export function getHealthcareCoverages(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getHealthcareCoveragesLoading());
      const rallyId = selectRallyId(getState());
      let benefitsData = selectBenefitsData(getState());
      if (!benefitsData) {
        await dispatch(getBenefits());
        benefitsData = selectBenefitsData(getState());
      }
      const showCarveoutClaims = !!(
        benefitsData &&
        benefitsData.benefits &&
        benefitsData.benefits.some(b => b.showRxCarveoutClaims)
      );
      const { data } = await ProfileApi.getHealthcareCoverages(rallyId, showCarveoutClaims);
      dispatch(getHealthcareCoveragesSuccess(data));
    } catch (error) {
      dispatch(getHealthcareCoveragesError());
    }
  };
}

export function getFinancialPreferences(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getFinancialPreferencesLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await ProfileApi.getFinancialPreferences(rallyId);
      dispatch(getFinancialPreferencesSuccess(data));
    } catch (error) {
      dispatch(getFinancialPreferencesError());
    }
  };
}

export function setEftPreferences(eftPreferences: IEftPreferences): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const rallyId = selectRallyId(getState());
      // update EFT preferences in the BE
      await ProfileApi.setEftPreferences(rallyId, eftPreferences);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  };
}

export function getProfileInfoForRallyPay(claim: IClaim | IHealthcareClaimDetails): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const rallyId = selectRallyId(getState());
      dispatch(getProfileInfoForRallyPayLoading());
      const { data } = await ProfileApi.getProfileInfoForRallyPay(rallyId, claim);
      dispatch(getProfileInfoForRallyPaySuccess(data));
    } catch (error) {
      dispatch(getProfileInfoForRallyPayError());
    }
  };
}

export function getUhcRewards(): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      dispatch(getUhcRewardsLoading());
      const { data, status } = await ProfileApi.getUhcRewards();

      // If user does not have eligiblity id, this will return with status 400.  In that case,
      // this will set the elibility to false.
      if (status !== 200) {
        console.warn(`getUhcRewards returned with status ${status}. isEligible will be set to false.`);
      }

      const uhcRewardsEligible = status === 200 ? data : false;
      dispatch(getUhcRewardsSuccess(uhcRewardsEligible));
    } catch (error) {
      console.error(error);
      dispatch(getUhcRewardsError());
    }
  };
}

export function getMedicareNudgeEligibility(policyNumber: string): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      dispatch(getMedicareNudgeEligibilityLoading());
      const { data } = await ProfileApi.getMedicareNudgeEligibility(policyNumber);
      dispatch(getMedicareNudgeEligibilitySuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(getMedicareNudgeEligibilityError());
    }
  };
}
